// Utils
import React from 'react';

// Assets
import VodiumLogo from '../images/vodium.svg';
import ArrowIcon from '../images/arrowRight.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setPrompterOnAction } from '../redux/actions/prompterOnActions';
import { setSettingsOpenAction } from '../redux/actions/settingsOpenActions';
import FileManager from '../filemanager/FileManager';
import Footer from '../component/footer';
import DarkMode from './darkMode';
import { Tooltip } from 'antd';
import SettingsBarContainer from '../styledComponent/settingsBarStyleComponent';

export default function SettingsBar({ onFileText, onFind }) {
  const settingsOpen = useSelector((state) => state.settingsOpen);
  const dispatch = useDispatch();

  const toggleSettings = () => {
    console.log(
      '{settingsbar} TRIGGERED toggleSettings SETTINGBAR',
      settingsOpen
    );
    dispatch(setPrompterOnAction(false));
    dispatch(setSettingsOpenAction(!settingsOpen));
  };

  return (
    <>
      <SettingsBarContainer
        className={settingsOpen ? 'settings-bar open' : 'settings-bar'}
      >
        <img className="logo" src={VodiumLogo} alt="Vodium Logo" />
        <div className={`inputs`}>
          <FileManager textScript={onFileText} findScript={onFind} />
          <DarkMode />
        </div>

        <div
          className={`settings-icon ${
            settingsOpen ? 'settings-bar open' : 'closed'
          } `}
          onClick={toggleSettings}
          style={{ bottom: '20px', marginRight: '-10px'}}
        >
          <Tooltip
            title={'Close Settings'}
            color="#273d57"
            overlayClassName="tooltip"
            placement="left"
            zIndex={999999}
          >
            <img
              className="arrow"
              src={ArrowIcon}
              alt="Close Settings"
              style={{ marginRight: '30px',  zIndex: 999999 }}
            />
          </Tooltip>
        </div>
      </SettingsBarContainer>
      <Footer />
    </>
  );
}
