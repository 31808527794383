/* eslint-disable import/no-anonymous-default-export */
import { SET_FILE_TITLE } from "../actionConstants";

export default (state = '', action) => {
  switch (action.type) {
    case SET_FILE_TITLE:
      return action.payload;
    default:
      return state;
  }
};
