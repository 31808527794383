import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faAngleLeft,
  faAngleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
const ModalContent = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: #021731;
  padding: 7px;
  border-radius: 5px;
  width: 300px;
  pointer-events: auto;
  animation-name: modal-animation;
  animation-duration: 0.5s;
  margin-top: -15px;
  height: 50px;
  display: flex;
  transform: translate(-0%, -0%);
  align-items: center;
  justify-content: center;
  .modal-dialog {
    display: inline-block;
    vertical-align: middle;
  }

  .modal .modal-content {
    padding: 1px;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
`;
const ModalCloseButton = styled.button`
  background-color: transparent; // set background-color to transparent
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 0;
  margin-right: 0;
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    background-color: transparent;
  }
`;
const ModalButtonSearch = styled.button`
  background-color: transparent; // set background-color to transparent
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 0;
  margin-right: 0;
  margin-left: 5px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    background-color: transparent;
  }
`;
const ModalButtonPrev = styled.button`
  background-color: transparent; // set background-color to transparent
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 0;
  margin-right: 0;
  margin-left: 15px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    background-color: transparent;
  }
`;
const ModalButtonNext = styled.button`
  background-color: transparent; // set background-color to transparent
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
  width: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-right: 0;
  margin-left: 15px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    background-color: transparent;
  }
`;

const ModalSearchInput = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 5px;
  padding-left: 30px; /* add padding to the left to make space for the icon */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23ccc' d='M10.29,9.71a5,5,0,1,0-1.41,1.41l3.2,3.2a1,1,0,0,0,1.41-1.41ZM5,8a3,3,0,1,1,3,3A3,3,0,0,1,5,8Z'/%3E%3C/svg%3E"); /* add the icon as a background image */
  background-repeat: no-repeat;
  background-position: 5px 50%; /* position the icon to the left and center vertically */
  width: 185px;
  height: 24px;
  font-size: 14px;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
`;

const Modal = ({ onClose, onSearch, onPrev, onNext }) => {
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);

  const handleSearchChange = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    try {
      if (searchValue.trim() === '') {
        const modalContent = document.querySelector('.modal-dialog');
        modalContent.classList.add('shake');
        setTimeout(() => {
          modalContent.classList.remove('shake');
        }, 500);
      } else {
        const canGoSearch = onSearch(searchValue);
        console.log('CAN GO SEARCH', canGoSearch);
        if (!canGoSearch) {
          console.log('No search found');
          const modalContent = document.querySelector('.modal-dialog');
          modalContent.classList.add('shake');
          setTimeout(() => {
            modalContent.classList.remove('shake');
          }, 500);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handlePrev = () => {
    try {
      const modalContent = document.querySelector('.modal-dialog');
      const canGoPrev = onPrev(searchValue);
      if (!canGoPrev) {
        modalContent.classList.add('shake');
        setTimeout(() => {
          modalContent.classList.remove('shake');
        }, 500);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleNext = () => {
    try {
      const modalContent = document.querySelector('.modal-dialog');
      const canGoNext = onNext(searchValue);
      if (!canGoNext) {
        modalContent.classList.add('shake');
        setTimeout(() => {
          modalContent.classList.remove('shake');
        }, 500);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleClose = () => {
    try {
      const modalContent = document.querySelector('.modal-content');
      modalContent.style.animationName = 'modal-close-animation';
      modalContent.style.animationDuration = '0.5s';
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleKeyDown = useCallback((event) => {
    try {
      if (event.key === 'Escape') {
        const modalContent = document.querySelector('.modal-content');
        modalContent.style.animationName = 'modal-close-animation';
        modalContent.style.animationDuration = '0.5s';
        setTimeout(() => {
          onClose();
        }, 500);
      }
    } catch (error) {
      console.log('error', error);
    }
  });
  const handlePressEnter = (event) => {
    try {
      if (event.key === 'Enter' || event.key === 13) {
        event.preventDefault();
        if (searchValue.trim() === '') {
          const modalContent = document.querySelector('.modal-dialog');
          modalContent.classList.add('shake');
          setTimeout(() => {
            modalContent.classList.remove('shake');
          }, 500);
        } else {
          const canGoSearch = onSearch(searchValue);
          console.log('CAN GO SEARCH', canGoSearch);
          if (!canGoSearch) {
            console.log('No Text found');
            const modalContent = document.querySelector('.modal-dialog');
            modalContent.classList.add('shake');
            setTimeout(() => {
              modalContent.classList.remove('shake');
            }, 500);
            return;
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleBlur = () => {
    document.removeEventListener('keydown', handleKeyDown);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  return (
    <ModalContainer>
      <div className="modal-dialog">
        <ModalContent className="modal-content">
          <div>
            <ModalSearchInput
              id="myInput"
              type="text"
              placeholder="Find Text"
              value={searchValue}
              onChange={handleSearchChange}
              ref={searchInputRef}
              onKeyDown={handlePressEnter}
              onBlur={handleBlur}
            />
            <ModalButtonSearch
              onClick={handleSearch}
              data-tip="Search"
              data-for="search-tooltip"
            >
              <FontAwesomeIcon icon={faSearch} />
            </ModalButtonSearch>
            <ModalButtonPrev
              onClick={handlePrev}
              data-tip="Previous"
              data-for="prev-tooltip"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </ModalButtonPrev>
            <ModalButtonNext
              onClick={handleNext}
              data-tip="Next"
              data-for="next-tooltip"
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </ModalButtonNext>
            <ModalCloseButton
              onClick={handleClose}
              data-tip="Close"
              data-for="close-tooltip"
            >
              <FontAwesomeIcon icon={faTimes} />
            </ModalCloseButton>
            <ReactTooltip
              id="search-tooltip"
              place="bottom"
              effect="solid"
              delayShow={500}
            />
            <ReactTooltip
              id="prev-tooltip"
              place="bottom"
              effect="solid"
              delayShow={500}
            />
            <ReactTooltip
              id="next-tooltip"
              place="bottom"
              effect="solid"
              delayShow={500}
            />
            <ReactTooltip
              id="close-tooltip"
              place="bottom"
              effect="solid"
              delayShow={500}
            />
            {/* {notification && <ModalNotification>{notification}</ModalNotification>} */}
          </div>
        </ModalContent>
      </div>
    </ModalContainer>
  );
};

export default Modal;
