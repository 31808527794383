import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setDarkModeAction } from '../redux/actions/darkModeAction';

const LightDarkModeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin-left: 45px;
`;

export default function DarkMode() {
  const darkMode = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();
  const lockWindow = () => {
    console.log('{LockWindow} TRIGGERED lockWindow ', darkMode);
    dispatch(setDarkModeAction(!darkMode));
  };
  return (
    <div>
      <LightDarkModeButton
        className="input-button "
        onClick={() => lockWindow()}
      >
        <span style={{ marginLeft: '-8px' }}>Dark Mode</span>
        <Tooltip
          title={darkMode ? 'Enable' : 'Disable'}
          color="#273d57"
          overlayClassName="tooltip"
        >
          <input
            className="switch"
            type="checkbox"
            checked={!!darkMode}
            onChange={() => {}}
            style={{ marginRight: '38px' }}
          />
        </Tooltip>
      </LightDarkModeButton>
    </div>
  );
}
