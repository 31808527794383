export const SET_PROMPTER_TEXT = 'SET_PROMPTER_TEXT';
export const SET_PRESENT_MODE = 'SET_PRESENT_MODE';
export const SET_PROMPTER_ON = 'SET_PROMPTER_ON';
export const SET_SETTINGS_OPEN = 'SET_SETTINGS_OPEN';
export const SET_SCROLL_DISTANCE = 'SET_SCROLL_DISTANCE';
export const SET_ADVANCE_SCROLL = 'SET_ADVANCE_SCROLL';
export const SET_PROMPTER_FONT_SIZE = 'SET_PROMPTER_FONT_SIZE';
export const SET_SCROLL_SPEED = 'SET_SCROLL_SPEED';
export const SET_WINDOWS_TRANSPARENCY = 'SET_WINDOWS_TRANSPARENCY';
export const SET_ALWAYS_ON_TOP = 'SET_ALWAYS_ON_TOP';
export const SET_SHOW_SETTINGS_ON_START = 'SET_SHOW_SETTINGS_ON_START';
export const SET_LOCK_WINDOW = 'SET_LOCK_WINDOW';
export const SET_IS_EDIT_MODE = 'SET_IS_EDIT_MODE';
export const SET_FILE_TITLE = 'SET_FILE_TITLE';
export const SET_FILE_TEXT = 'SET_FILE_TEXT';
export const SET_LICENSEKEY = 'SET_LICENSEKEY';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_OVERLAY_MODE = 'SET_OVERLAY_MODE';
export const SET_IS_IN_TEAMS = 'SET_IS_IN_TEAMS';
export const SET_TEAMS_CONTEXT = 'SET_TEAMS_CONTEXT';
export const SET_IS_IN_MOBILE = 'SET_IS_IN_MOBILE';
