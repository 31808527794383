import {
  VODIUM_SIGN_IN,
  VODIUM_AUTH_WEB_TOKEN,
  VODIUM_WEB_APP,
  VODIUM_SECRET_PASS,
} from '../firebase-config';
import { userAuthentication } from '../api/api';
import CryptoJS from "crypto-js";

export const refreshPage = () => {
  window.location.href = VODIUM_WEB_APP;
};

//Returns the user to the sign in page if accessing the launch page without logging in
export const redirectSignIn = () => {
  localStorage.removeItem(VODIUM_AUTH_WEB_TOKEN);
  window.location.href = VODIUM_SIGN_IN; //Comment this out when running the app locally
};


const authenticate = async (idToken) => {
  console.log('token', idToken);
  // const tokenss = localStorage.getItem('VODIUM_AUTH_WEB_TOKEN');
  try {
    if (idToken) {
      const email = await verifyUser(idToken);
      console.log('Authenticate Email', email);
      refreshPage();
      return email;
    } else {
      const token = localStorage.getItem(VODIUM_AUTH_WEB_TOKEN);
      if (token) {
        await verifyUser(token);
        console.log('VERIFY TOKEN', token);
      } else {
        console.log('authenticate else');
        redirectSignIn();
      }
    }
  } catch (error) {
    console.log('Authenticate error :', error);
  }
};

const verifyUser = async (token) => {
  console.log('Verify User Token', token);
  try {
    const result = await userAuthentication(token);
    console.log('RESULT VERIFYUSER', result);
    window.localStorage.setItem(VODIUM_AUTH_WEB_TOKEN, token);
    console.log('Verify Token Result', result);
    return result;
  } catch (error) {
    redirectSignIn();
    console.log('verify catch');
    throw error;
  }
};

const dateValid = (fromToken) => {
  try {
    let date1 = new Date(fromToken);
    let date2 = new Date(getCurrentDateTimeUTC());

    date2.setHours(date2.getHours() - 1);

    var time1 = date1.getTime();
    var time2 = date2.getTime();

    if (time1 < time2) {
      return false;
    } else if (time1 >= time2) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

const getCurrentDateTimeUTC = () => {
  const date = new Date();
  var day = date.getUTCMonth() + 1;
  return (
    [
      date.getFullYear(),
      day < 10 ? "0" + day : day, //0 = January, 1 = February, etc.
      date.getUTCDate(),
    ].join('/')
  );
}

const decryptData = (tokenValue) => {
  try {
    const bytes = CryptoJS.AES.decrypt(tokenValue, VODIUM_SECRET_PASS);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    return null;
  }
};

export const validFromTeamsApp = (tokenValue) => {
  var data = decryptData(tokenValue);
  return dateValid(data);
}

export default authenticate;
