/* eslint-disable default-case */
export const calculateScrollSpeed = (scrollSpeed) => {
  const modifySpeed = 1;
  switch (scrollSpeed) {
    case 1:
      return 0.04082 + modifySpeed;
    case 2:
      return 0.05187 + modifySpeed;
    case 3:
      return 0.06292 + modifySpeed;
    case 4:
      return 0.07423 + modifySpeed;
    case 5:
      return 0.08515 + modifySpeed;
    case 6:
      return 0.09633 + modifySpeed;
    case 7:
      return 0.10725 + modifySpeed;
    case 8:
      return 0.11843 + modifySpeed;
    case 9:
      return 0.12948 + modifySpeed;
    case 10:
      return 0.14053 + modifySpeed;
    case 11:
      return 0.15158 + modifySpeed;
    case 12:
      return 0.16276 + modifySpeed;
    case 13:
      return 0.17381 + modifySpeed;
    case 14:
      return 0.18486 + modifySpeed;
    case 15:
      return 0.19591 + modifySpeed;
    case 16:
      return 0.20696 + modifySpeed;
    case 17:
      return 0.21814 + modifySpeed;
    case 18:
      return 0.22919 + modifySpeed;
    case 19:
      return 0.24024 + modifySpeed;
    case 20:
      return 0.25129 + modifySpeed;
    case 21:
      return 0.26247 + modifySpeed;
    case 22:
      return 0.27352 + modifySpeed;
    case 23:
      return 0.28457 + modifySpeed;
    case 24:
      return 0.29562 + modifySpeed;
    case 25:
      return 0.30693 + modifySpeed;
    case 26:
      return 0.31785 + modifySpeed;
    case 27:
      return 0.32903 + modifySpeed;
    case 28:
      return 0.33995 + modifySpeed;
    case 29:
      return 0.35113 + modifySpeed;
    case 30:
      return 0.36218 + modifySpeed;
    case 31:
      return 0.37323 + modifySpeed;
    case 32:
      return 0.38428 + modifySpeed;
    case 33:
      return 0.39533 + modifySpeed;
    case 34:
      return 0.40651 + modifySpeed;
    case 35:
      return 0.41756 + modifySpeed;
    case 36:
      return 0.42861 + modifySpeed;
    case 37:
      return 0.43966 + modifySpeed;
    case 38:
      return 0.45084 + modifySpeed;
    case 39:
      return 0.46189 + modifySpeed;
    case 40:
      return 0.47294 + modifySpeed;
    case 41:
      return 0.48399 + modifySpeed;
    case 42:
      return 0.49517 + modifySpeed;
    case 43:
      return 0.50622 + modifySpeed;
    case 44:
      return 0.51727 + modifySpeed;
    case 45:
      return 0.52832 + modifySpeed;
    case 46:
      return 0.53937 + modifySpeed;
    case 47:
      return 0.55055 + modifySpeed;
    case 48:
      return 0.56173 + modifySpeed;
    case 49:
      return 0.57265 + modifySpeed;
    case 50:
      return 0.58383 + modifySpeed;
    case 51:
      return 0.59488 + modifySpeed;
    case 52:
      return 0.60593 + modifySpeed;
    case 53:
      return 0.61698 + modifySpeed;
    case 54:
      return 0.62803 + modifySpeed;
    case 55:
      return 0.63921 + modifySpeed;
    case 56:
      return 0.65026 + modifySpeed;
    case 57:
      return 0.66131 + modifySpeed;
    case 58:
      return 0.67236 + modifySpeed; 
    case 59:
      return 0.68341 + modifySpeed;
    case 60:
      return 0.69459 + modifySpeed;
    case 61:
      return 0.70564 + modifySpeed;
    case 62:
      return 0.71669 + modifySpeed;
    case 63:
      return 0.72774 + modifySpeed;
    case 64:
      return 0.73892 + modifySpeed;
    case 65:
      return 0.74997 + modifySpeed;
    case 66:
      return 0.76102 + modifySpeed;
    case 67:
      return 0.77207 + modifySpeed;
    case 68:
      return 0.78325 + modifySpeed;
    case 69:
      return 0.79443 + modifySpeed;
    case 70:
      return 0.80535 + modifySpeed;
    case 71:
      return 0.81653 + modifySpeed;
    case 72:
      return 0.82745 + modifySpeed;
    case 73:
      return 0.83863 + modifySpeed;
    case 74:
      return 0.84968 + modifySpeed;
    case 75:
      return 0.86073 + modifySpeed;
    case 76:
      return 0.87178 + modifySpeed;
    case 77:
      return 0.88296 + modifySpeed;
    case 78:
      return 0.89401 + modifySpeed;
    case 79:
      return 0.90506 + modifySpeed;
    case 80:
      return 0.91611 + modifySpeed;
    case 81:
      return 0.92729 + modifySpeed;
    case 82:
      return 0.93834 + modifySpeed;
    case 83:
      return 0.94939 + modifySpeed;
    case 84:
      return 0.96044 + modifySpeed;
    case 85:
      return 0.97162 + modifySpeed;
    case 86:
      return 0.98267 + modifySpeed;
    case 87:
      return 0.99372 + modifySpeed;
    case 88:
      return 1.00477 + modifySpeed;
    case 89:
      return 1.01582 + modifySpeed;
    case 90:
      return 1.02713 + modifySpeed;
    case 91:
      return 1.03805 + modifySpeed;
    case 92:
      return 1.04923 + modifySpeed;
    case 93:
      return 1.06015 + modifySpeed;
    case 94:
      return 1.07133 + modifySpeed;
    case 95:
      return 1.08238 + modifySpeed;
    case 96:
      return 1.09343 + modifySpeed;
    case 97:
      return 1.10448 + modifySpeed;
    case 98:
      return 1.11566 + modifySpeed;
    case 99:
      return 1.12671 + modifySpeed;
    case 100:
      return 1.13776 + modifySpeed;
  }
};
