/* eslint-disable import/no-anonymous-default-export */
import { SET_IS_IN_MOBILE } from '../actionConstants';

export default (state = false, action) => {
  switch (action.type) {
    case SET_IS_IN_MOBILE:
      return action.payload;
    default:
      return state;
  }
};
