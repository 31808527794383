/* eslint-disable import/no-anonymous-default-export */
import { SET_LICENSEKEY } from '../actionConstants';

export default (state = '', action) => {
  switch (action.type) {
    case SET_LICENSEKEY:
      return action.payload;
    default:
      return state;
  }
};
