// Utils
import React, { useEffect } from 'react';

// Assets
import FontSizeIcon from '../images/increasefontsize-svgrepo-com.svg';
import Slider from 'react-rangeslider';
import { useDispatch, useSelector } from 'react-redux';

import { setPrompterFontSizeAction } from '../redux/actions/prompterFontSizeActions';
import { licenseConfigurationPOST } from '../api/api';
import FontSizeButton from '../styledComponent/fontSizeStyleComponent';
// CSS

export default function FontSize({ fontSize }) {
  const dispatch = useDispatch();
  const prompterFontSize = useSelector((state) => state.prompterFontSize);
  const scrollSpeed = useSelector((state) => state.scrollSpeed);
  const licenseKey = useSelector((state) => state.licenseKey);
  const updateFontSize = (e) => {
    dispatch(setPrompterFontSizeAction(e));
  };
  useEffect(() => {
    setTimeout(async () => {
      const scrollS = await scrollSpeed;
      try {
        await licenseConfigurationPOST(
          licenseKey,
          prompterFontSize,
          scrollS
        );
        // console.log('FONTSIZE', response);
      } catch (error) {
        console.log('API request failed');
      }
    });
  }, [scrollSpeed,prompterFontSize]);
  let timeoutId = null;
  const handleSliderMouseUp = async () => {
    clearTimeout(timeoutId);
  };

  return (
    <FontSizeButton className="input-button">
      <div className="input-container">
        <div
          className={fontSize ? 'input-interface' : 'input-interface hidden'}
        >
          <span>{prompterFontSize}</span>
          <div onMouseUp={handleSliderMouseUp}>
            <Slider
              min={12}
              max={72}
              step={1}
              value={prompterFontSize}
              orientation="vertical"
              tooltip={false}
              onChange={(e) => updateFontSize(e)}
            />
          </div>
          <span className="lable">Font Size</span>
        </div>
        <img src={FontSizeIcon} alt="Font Size Icon" className="fontIcon" />
      </div>
    </FontSizeButton>
  );
}
