import React from "react";

function Footer() {
  return (
    <footer>
      <div id="footer" className="w-full min-[280px]:h-7 md:h-10">
        <div className="min-[280px]:pl-2 sm:pl-4 md:pl-8 min-[280px]:pt-1 md:pt-2">
          <p className="text-left min-[280px]:text-xs md:text-sm font-semibold ">
        
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
