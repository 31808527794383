// Utils
import React from 'react';
import styled from 'styled-components';

// Components
import StartPresenting from './StartPresenting';
import { useSelector } from 'react-redux';
// CSS
const ToolBarContainer = styled.div`
  width: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
  position: inherit;
  z-index: 5;
  transition: opacity 0.5s;

  .present-button {
    /* Animated BG */
    min-height: 52px;
    background: linear-gradient(127deg, #282c34, #5954a4);
    background-size: 400% 400%;
    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
    box-shadow: 4px 4px 8px #22252c, -4px -4px 8px #2e333c;
    padding: 0 6px;
    margin: 0 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    cursor: pointer;

    @-webkit-keyframes AnimationName {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
    @-moz-keyframes AnimationName {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
    @keyframes AnimationName {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  }

  .present-button.open {
    background-image: repeating-linear-gradient(
      -45deg,
      #ac74f5,
      #ac74f5 1rem,
      #b680f8 1rem,
      #b680f8 2rem
    );
    background-size: 200% 200%;
    animation: barberpole 10s linear infinite;
  }

  .present-button.running {
    background-image: repeating-linear-gradient(
      -45deg,
      #ff0217,
      #ff0217 1rem,
      #ff5c69 1rem,
      #ff5c69 2rem
    );
    background-size: 200% 200%;
    animation: barberpole 10s linear infinite;
  }

  @keyframes barberpole {
    100% {
      background-position: 100% 100%;
    }
  }

  .inputs {
    display: flex;

    .input-button {
      height: 40px;
      width: max-content;
      padding: 6px;
      min-width: 40px;
      border-radius: 6px;
      background: #282c34;
      box-shadow: 4px 4px 8px #22252c, -4px -4px 8px #2e333c;

      > div {
        padding: 0 6px;
      }
    }

    .input-button.activated {
      background: #5954a4;
    }
  }
`;

export default function ToolBar() {
  const isEditMode = useSelector((state) => state.isEditMode);

  return (
    <ToolBarContainer className={isEditMode ? 'readonly-mode' : ''}>
      <StartPresenting />
    </ToolBarContainer>
  );
}
