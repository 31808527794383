import styled from 'styled-components';
const FontSizeButton = styled.div`
  .input-container {
    position: relative;
    display: flex;
    cursor: pointer;
    .fontIcon {
      width: 40px;
      height: 22px;
    }
  }

  .input-interface {
    box-shadow: 8px -2px 17px 1px rgba(0, 0, 0, 0.27);
    position: absolute;
    background-color: #273d57;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    bottom: 50px;
    height: 165px;
    width: 43px;
    left: 18px; 
    right: 0;
    font-size: 14px;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    color: white;
    .input-value {
      color: #ffffff;
      font-size: 17px;
      font-weight: 500;
      width: 20px;
      text-align: center;
      margin: 0 0 0 12px;
      transform: rotate(90deg);
    }

    .lable {
      font-size: 11px;
      font-weight: 600;
      margin: 0 auto;
      display: block;
      max-width: 45px;
    }

    img {
      transform: scaleY(-1);
    }

    /*** Rangeslider ***/

    .rangeslider {
      position: relative;
      background: #e6e6e6;
      -ms-touch-action: none;
      touch-action: none;
    }

    .rangeslider,
    .rangeslider .rangeslider__fill {
      display: block;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
    }

    .rangeslider .rangeslider__handle {
      background: #ffffff;
      border: 1px solid #ccc;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
    }

    .rangeslider .rangeslider__handle .rangeslider__active {
      opacity: 1;
    }

    .rangeslider .rangeslider__handle-tooltip {
      width: 40px;
      height: 40px;
      text-align: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      font-weight: normal;
      font-size: 14px;
      transition: all 100ms ease-in;
      border-radius: 4px;
      display: inline-block;
      color: white;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }

    .rangeslider .rangeslider__handle-tooltip span {
      margin-top: 12px;
      display: inline-block;
      line-height: 100%;
    }

    .rangeslider .rangeslider__handle-tooltip:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    }

    /*** Rangeslider - Horizontal slider ***/

    .rangeslider-horizontal {
      height: 12px;
      border-radius: 10px;
    }

    .rangeslider-horizontal .rangeslider__fill {
      height: 100%;
      background-color: #7cb342;
      border-radius: 10px;
      top: 0;
    }

    .rangeslider-horizontal .rangeslider__handle {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .rangeslider-horizontal .rangeslider__handle:after {
      content: ' ';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 6px;
      left: 6px;
      border-radius: 50%;
      background-color: #dadada;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset,
        0 -1px 3px rgba(0, 0, 0, 0.4) inset;
    }

    .rangeslider-horizontal .rangeslider__handle-tooltip {
      top: -65px;
    }

    .rangeslider-horizontal .rangeslider__handle-tooltip:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.8);
      left: 50%;
      bottom: -8px;
      transform: translate3d(-50%, 0, 0);
    }

    /*** Rangeslider - Vertical slider ***/

    .rangeslider-vertical {
      height: 112px;
      max-width: 4px;
      background-color: #e5e5e5;
      border-radius: 5px;
    }

    .rangeslider-vertical .rangeslider__fill,
    .rangeslider-vertical .rangeslider__handle {
      position: absolute;
    }

    .rangeslider-vertical .rangeslider__fill {
      width: 100%;
      background-color: #5954a4;
      box-shadow: none;
      bottom: 0;
      border-radius: 5px;
    }

    .rangeslider-vertical .rangeslider__handle {
      width: 16px;
      height: 16px;
      left: -7px;
      box-shadow: none;
      border-radius: 100%;
    }

    .rangeslider-vertical .rangeslider__handle-label {
      position: absolute;
      right: 22px;
      padding: 2px;
      font-size: 14px;
    }

    .rangeslider-vertical .rangeslider__handle-tooltip {
      left: -100%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .rangeslider-vertical .rangeslider__handle-tooltip:after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid rgba(0, 0, 0, 0.8);
      left: 100%;
      top: 12px;
    }

    /*** Rangeslider - Reverse ***/

    .rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
      right: 0;
    }

    .rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
      top: 0;
      bottom: inherit;
    }

    /*** Rangeslider - Labels ***/

    .rangeslider__labels {
      position: relative;
    }

    .rangeslider-vertical .rangeslider__labels {
      position: relative;
      list-style-type: none;
      margin: 0 0 0 24px;
      padding: 0;
      text-align: left;
      width: 250px;
      height: 100%;
      left: 10px;
    }

    .rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
      position: absolute;
      transform: translate3d(0, -50%, 0);
    }

    .rangeslider-vertical
      .rangeslider__labels
      .rangeslider__label-item::before {
      content: '';
      width: 10px;
      height: 2px;
      background: black;
      position: absolute;
      left: -14px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

    .rangeslider__labels .rangeslider__label-item {
      position: absolute;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      top: 10px;
      transform: translate3d(-50%, 0, 0);
    }
  }

  @media(min-width:260px) and (max-width:280px){
    .input-interface {
      position:absolute;
      left:5px;
      bottom:25px;
    }
  }

  @media(min-width:320px) and (orientation:portrait) {
    .input-interface {
      position:absolute;
      left:8px;
      bottom:32px;
    }
  }

  @media only screen and (min-width:401px) and (max-width:500px) {
    .input-interface {
      position:absolute;
      left:8px;
      bottom:40px;
    }
  }

  .input-interface.hidden {
    display: none;
  }
`;
export default FontSizeButton;
