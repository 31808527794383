import styled from 'styled-components';
// CSS
const SettingsBarContainer = styled.div`
  z-index: 9999;
  background: #021731;
  position: absolute;
  display: flex;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
  align-items: flex-end;
  transition: opacity 0.5s linear;
  .settings-icon {
    width: 52px;
    height: 40px;
    position: absolute;
    background: #021731;
    bottom: 25px;
    border-bottom-left-radius: 22px;
    border-top-left-radius: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -2px 0 3px 0 rgba(0, 0, 0, 0.1), 0 0 12px 4px rgba(0, 0, 0, 0);
    transition: all 0.2s linear;
    margin-right: 100px;
    z-index: 9999;
    img {
      width: 16px;
      height: 16px;
      z-index: 9999;
    }
    .arrow {
      width: 24px;
      height: 24px;
      transition: all 0.2s ease-in-out;
      z-index: 9999;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .logo {
    width: 142px;
    height: 30px;
    align-self: center;
    margin-top: 30px;
  }

  hr {
    width: 84%;
    margin: 18px 0;
    opacity: 0.2;
    border-width: 0.5px;
  }

  .inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


    .separator {
      height: 1px;
      width: 100%;
      background: #000;
      margin: 15px 0;
    }
  }
  @media (min-width: 768px) {
    .inputs {
      margin-top: calc(100vh - 470px) !important;
      margin-top: 280px;
      position: absolute;
    }
  }
  @media (max-width: 768px) {
    .inputs {
      margin-top: 80px;
      position: absolute;
    }
  }
  .input-button {
    width: 215px;
    display: flex;
    align-items: center;
    .input-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 22px;
        align-self: baseline;
      }

      .input-value {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        width: 20px;
        text-align: center;
      }
    }

    span {
      display: block;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }

    .switch {
      -webkit-appearance: none;
      height: 22px;
      width: 37px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 100px;
      position: relative;
      cursor: pointer;
      margin: 0;

      &::after {
        top: 1px;
        left: 1px;
        content: '';
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        position: absolute;
        border-radius: 100%;
        transition: 0.5s;
      }

      &:checked {
        background-color: #5954a4;

        &::after {
          transform: translateX(15px);
        }
      }

      &:focus {
        outline: none;
      }
    }

    p {
      margin: 0;
      color: #ffffff;
      font-size: 14px;
      text-align: left;
      font-weight: 500;
    }
  }

  .input-button.toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 215px;
    cursor: pointer;
  }
`;
export default SettingsBarContainer;
