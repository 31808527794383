// Utils
import React from 'react';

// Assets
import ScrollSpeedIcon from '../images/fontSize.svg';
import Slider from 'react-rangeslider';
import { useDispatch, useSelector } from 'react-redux';
import { setScrollSpeedAction } from '../redux/actions/scrollSpeedActions';
import { licenseConfigurationPOST } from '../api/api';
import { useEffect } from 'react';
import ScrollSpeedButton from '../styledComponent/scrollSpeedStyleComponent';
///save this comment for future implementing Google analytics
// import { sendAnalyticsData } from '../../../googleAnalytics/analyticsData';
// import { v4 as uuidv4 } from 'uuid';

export default function ScrollSpeed({ showScrollSlider }) {
  const dispatch = useDispatch();
  const scrollSpeed = useSelector((state) => state.scrollSpeed);
  const prompterFontSize = useSelector((state) => state.prompterFontSize);
  const licenseKey = useSelector((state) => state.licenseKey);
  const updateScrollSpeed = (e) => {
    dispatch(setScrollSpeedAction(e));
    console.log('SCROLL SPEEED', scrollSpeed);
  };
  useEffect(() => {
    setTimeout(async () => {
      const fontS = await prompterFontSize;
      try {
        await licenseConfigurationPOST(
          licenseKey,
          fontS,
          scrollSpeed
        );
        // console.log('Scroll speed Response', response);
      } catch (error) {
        console.log('API request failed');
      }
    });
  }, [scrollSpeed,prompterFontSize]);
  let timeoutId = null;
  const handleSliderMouseUp = async () => {
    clearTimeout(timeoutId);
  };

  return (
    <ScrollSpeedButton className="input-button">
      <div className="input-container">
        <div
          className={
            showScrollSlider ? 'input-interface' : 'input-interface hidden'
          }
        >
          <span>{scrollSpeed}</span>
          <div onMouseUp={handleSliderMouseUp}>
            <Slider
              min={1}
              max={100}
              step={1}
              value={scrollSpeed}
              orientation="vertical"
              tooltip={false}
              onChange={(e) => updateScrollSpeed(e)}
            />
          </div>
          <span className="lable">Scroll Speed</span>
        </div>
        <img src={ScrollSpeedIcon} alt="Text Speed Icon" className="speedIcon" />
      </div>
    </ScrollSpeedButton>
  );
}
