import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './app.global.css';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/store/configureStore';
import { Provider } from 'react-redux';
import { setScrollSpeedAction } from './redux/actions/scrollSpeedActions';
import { setPrompterTextAction } from './redux/actions/prompterTextActions';
import { setFileTitleAction } from './redux/actions/fileTitleAction';
import { setFileTextAction } from './redux/actions/setFileTextAction';
import { setLicenseKeyAction } from './redux/actions/licenseKeyActions';
import { setPrompterFontSizeAction } from './redux/actions/prompterFontSizeActions';
import { setDarkModeAction } from './redux/actions/darkModeAction';
const store = configureStore();

const savedSettings = JSON.parse(localStorage.getItem('settings') || '{}');

if (savedSettings.prompterText) {
  store.dispatch(setPrompterTextAction(savedSettings.prompterText));
}
if (savedSettings.licenseKey) {
  store.dispatch(setLicenseKeyAction(savedSettings.licenseKey));
}
if (savedSettings.fileText) {
  store.dispatch(setFileTextAction(savedSettings.fileText));
}
if (savedSettings.scrollSpeed) {
  store.dispatch(setScrollSpeedAction(savedSettings.scrollSpeed));
}
if (savedSettings.fileTitle !== undefined) {
  store.dispatch(setFileTitleAction(savedSettings.fileTitle));
}
if (savedSettings.fontSize) {
  store.dispatch(setPrompterFontSizeAction(savedSettings.fontSize));
}
if (savedSettings.darkMode !== undefined) {
  store.dispatch(setDarkModeAction(savedSettings.darkMode));
}
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
