/* eslint-disable import/no-anonymous-default-export */
import { SET_PROMPTER_FONT_SIZE } from '../actionConstants';

export default (state = 42, action) => {
  switch (action.type) {
    case SET_PROMPTER_FONT_SIZE:
      return action.payload;
    default:
      return state;
  }
};
