// Utils
import React, { useState } from 'react';
import styled from 'styled-components';
// Components
import ScriptWindows from './ScriptWindow';
import { useSelector, useDispatch } from 'react-redux';
import SettingsIcon from '../images/settings.svg';
import { setPrompterTextAction } from '../redux/actions/prompterTextActions';
import { setPrompterOnAction } from '../redux/actions/prompterOnActions';
import { setSettingsOpenAction } from '../redux/actions/settingsOpenActions';
import { Tooltip } from 'antd';
import SettingsBar from './settingsbar';
import { useEffect } from 'react';
// CSS
const PrompterContainer = styled.div`
  height: calc(100vh - 95px);
  width: 99%;
  left: -13px;
  top: -33px;
  // border-radius: 6px; /// textArea radius
  transition: box-shadow 0.3s ease-in-out !important;
  position: relative;
  // box-shadow: 0 0 0 1px #fff; //// textArea
  .guide {
    position: absolute;
    height: 48px;
    width: 100%;
    margin: 2px auto;
    background: #000000;
    opacity: 0.5;
    border-radius: 6px;
    display: none;
  }

  .shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;
export default function Prompter() {
  const isEditMode = useSelector((state) => state.isEditMode);
  const prompterText = useSelector((state) => state.prompterText);
  const [fileText, setFileText] = useState(prompterText);
  const [onFindText, setOnFindText] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const dispatch = useDispatch();
  const settingsOpen = useSelector((state) => state.settingsOpen);
  const handleFileText = (text) => {
    dispatch(setPrompterTextAction(text));
    setFileText(text);
  };
  const handleFindTexxt = (value) => {
    setOnFindText((value) => !value);
  };
  const toggleSettings = () => {
    console.log('{APP}', 'TRIGGERED toggleSettings PROMPTER', settingsOpen);
    dispatch(setPrompterOnAction(false));
    dispatch(setSettingsOpenAction(!settingsOpen));
  };
  useEffect(() => {
    if (!settingsOpen) {
      dispatch(setSettingsOpenAction(settingsOpen));
    } else {
      dispatch(setSettingsOpenAction(true));
    }
  }, [settingsOpen, dispatch]);
  const setModalCloseFindScript = (value) => {
    setModalClose(value);
    setOnFindText(modalClose);
  };

  return (
    <>
      <PrompterContainer
        id="prompter-container"
        className={`prompter-container ${
          isEditMode ? 'prompter-container-edit' : ''
        }`}
        style={{ margin: '30px 24px 15px 17px', marginBottom: '20px' }}
      >
        <ScriptWindows
          fileText={fileText}
          findText={onFindText}
          setFindText={setModalCloseFindScript}
        />

        <div
          // className={'icon-wrap'}
          onClick={toggleSettings}
          // style={{ bottom: '20px' }}
        >
          <div className={settingsOpen ? 'icon-wrap' : 'icon-wrap on'}>
            <Tooltip
              title={'Open Settings'}
              color="#273d57"
              overlayClassName="tooltip"
              placement="left"
              zIndex={99999}
            >
              <img
                style={{
                  cursor: 'pointer',
                  bottom: '250px',
                  marginRight: '30px',
                }}
                src={SettingsIcon}
                alt="Open Settings"
              />
            </Tooltip>
          </div>
        </div>
      </PrompterContainer>
      <SettingsBar onFileText={handleFileText} onFind={handleFindTexxt} />
    </>
  );
}
