import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration PROD ENVIRONMENT
const firebaseConfig = {
  apiKey: 'AIzaSyCxqpNWkBHw9XlGCtmJYWwhrHxQ8nmphuk',
  authDomain: 'vodium-web-application.firebaseapp.com',
  projectId: 'vodium-web-application',
  storageBucket: 'vodium-web-application.appspot.com',
  messagingSenderId: '603443093821',
  appId: '1:603443093821:web:4f714d5d42b2af48cbe554',
  measurementId: 'G-6577XZ1T4X',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const VODIUM_SIGN_IN = 'https://vodium.com/sign-in';
export const VODIUM_AUTH_WEB_TOKEN = 'VODIUM_AUTH_WEB_TOKEN';
export const VODIUM_WEB_APP = 'https://lite.vodium.com/';
export const BASE_URL = 'https://download.vodium.com';
export const VODIUM_SECRET_PASS = "vodium-TdGvWyNSOZ ";
