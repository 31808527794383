/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import prompterTextReducer from '../reducers/prompterTextReducer';
import presentModeReducer from '../reducers/presentModeReducer';
import prompterOnReducer from '../reducers/prompterOnReducer';
import settingsOpenReducer from '../reducers/settingsOpenReducer';
import scrollDistanceReducer from '../reducers/scrollDistanceReducer';
import advanceScrollReducer from '../reducers/advanceScrollReducer';
import prompterFontSizeReducer from '../reducers/prompterFontSizeReducer';
import scrollSpeedReducer from '../reducers/scrollSpeedReducer';
import windowTransparencyReducer from '../reducers/windowTransparencyReducer';
import alwaysOnTopReducer from '../reducers/alwaysOnTopReducer';
import showSettingsOnStartReducer from '../reducers/showSettingsOnStartReducer';
import lockWindowReducer from '../reducers/lockWindowReducer';
import isEditModeReducer from '../reducers/isEditModeReducer';
import fileTitleReducer from '../reducers/fileTitleReducer';
import setFileTextReducer from '../reducers/setFileTextReducer';
import licenseKeyReduer from '../reducers/licenseKeyReduer';
import darkModeReducer from '../reducers/darkModeReducer';
import overlayModeReducer from '../reducers/overlayModeReducer';
import isInTeamsReducer from '../reducers/isInTeamsReducer';
import teamsContextReducer from '../reducers/teamsContextReducer';
import inMobileReducer from '../reducers/inMobileReducer';
export default () => {
  const store = createStore(
    combineReducers({
      prompterText: prompterTextReducer,
      presentMode: presentModeReducer,
      prompterOn: prompterOnReducer,
      settingsOpen: settingsOpenReducer,
      scrollDistance: scrollDistanceReducer,
      advanceScroll: advanceScrollReducer,
      prompterFontSize: prompterFontSizeReducer,
      scrollSpeed: scrollSpeedReducer,
      windowTransparency: windowTransparencyReducer,
      alwaysOnTop: alwaysOnTopReducer,
      showSettingsOnStart: showSettingsOnStartReducer,
      lockWindow: lockWindowReducer,
      isEditMode: isEditModeReducer,
      fileTitle: fileTitleReducer,
      fileText: setFileTextReducer,
      licenseKey: licenseKeyReduer,
      darkMode: darkModeReducer,
      overlayOn: overlayModeReducer,
      inTeams: isInTeamsReducer,
      teamsContext: teamsContextReducer,
      inMobile: inMobileReducer
    }),

    compose(
      applyMiddleware(thunk)
      // ,
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );

  return store;
};
