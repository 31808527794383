import React from 'react';
import { useSelector } from 'react-redux';

export default function PlaceHolder() {
  const darkMode = useSelector((state) => state.darkMode);
  return (
    <div
      style={{
        position: 'absolute',
        top: '12px',
        left: '15px',
        pointerEvents: 'none',
        color: darkMode ? 'white' : 'black',
        textAlign: 'left',
        fontSize: '15px',
      }}
      className="placeHolder"
    >
      <strong>To get started with VODIUM, you can:</strong>

      <ul style={{ marginLeft: '30px' }}>
        <li>Type directly in this text box</li>
        <li>Paste text from another document</li>
        <li>Upload a script or file</li>
      </ul>
      <div>
        Don't forget you also have{' '}
        <strong>
          <em>
            <u>rich text formatting,</u>
            <br />
          </em>
        </strong>{' '}
        and can adjust the size of your font, and the speed of the autoscroll.
      </div>
      <p>
        <em>
          <br />
          The VODIUM is now yours!
          <br />
        </em>
      </p>
    </div>
  );
}
