import { Quill } from 'react-quill';

let icons = Quill.import('ui/icons');

icons['bold'] =
  '<svg width="8" height="11" viewBox="0 0 8 11" fill="white" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M0 11V0H4.44878C5.35935 0 6.11707 0.275 6.72195 0.825C7.32683 1.375 7.62927 2.06905 7.62927 2.90714C7.62927 3.43095 7.50244 3.89256 7.24878 4.29196C6.99512 4.69137 6.62114 5.00893 6.12683 5.24464V5.3625C6.73821 5.55893 7.20325 5.87976 7.52195 6.325C7.84065 6.77024 8 7.30714 8 7.93571C8 8.85238 7.68455 9.59226 7.05366 10.1554C6.42276 10.7185 5.6 11 4.58537 11H0ZM1.67805 4.65536H4.33171C4.82602 4.65536 5.22927 4.50804 5.54146 4.21339C5.85366 3.91875 6.00976 3.53571 6.00976 3.06429C6.00976 2.59286 5.85366 2.20655 5.54146 1.90536C5.22927 1.60417 4.82602 1.45357 4.33171 1.45357H1.67805V4.65536ZM1.67805 9.50714H4.4878C5.06016 9.50714 5.50894 9.35327 5.83415 9.04554C6.15935 8.7378 6.32195 8.31548 6.32195 7.77857C6.32195 7.25476 6.15935 6.83899 5.83415 6.53125C5.50894 6.22351 5.06016 6.06964 4.4878 6.06964H1.67805V9.50714Z" fill="white"/>\n' +
  '</svg>';

icons['italic'] =
  '<svg width="10" height="12" viewBox="0 0 10 12" fill="white" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M0.333496 11.3333V9.66666H2.61683L5.5835 2.33332H3.00016V0.666656H9.66683V2.33332H7.3835L4.41683 9.66666H7.00016V11.3333H0.333496Z" fill="white"/>\n' +
  '</svg>\n';

icons['underline'] =
  '<svg width="10" height="12" viewBox="0 0 10 12" fill="white" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M5.00016 9.33333C3.87794 9.33333 2.93072 8.95 2.1585 8.18333C1.38627 7.41667 1.00016 6.47778 1.00016 5.36667V0H2.3335V5.4C2.3335 6.13333 2.58905 6.75 3.10016 7.25C3.61127 7.75 4.24461 8 5.00016 8C5.75572 8 6.38905 7.75 6.90016 7.25C7.41127 6.75 7.66683 6.13333 7.66683 5.4V0H9.00016V5.36667C9.00016 6.47778 8.61405 7.41667 7.84183 8.18333C7.06961 8.95 6.12239 9.33333 5.00016 9.33333ZM0.333496 12V11H9.66683V12H0.333496Z" fill="white"/>\n' +
  '</svg>\n';

icons['list']['ordered'] =
  '<svg width="12" height="12" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M0 11.3333V10.7667H1.4V10.3333H0.7V9.76666H1.4V9.33332H0V8.76666H1.96667V11.3333H0ZM0.7 3.26666V1.23332H0V0.666656H1.26667V3.26666H0.7ZM0 7.28332V6.74999L1.25 5.28332H0V4.71666H1.96667V5.24999L0.7 6.71666H1.96667V7.28332H0ZM3.48333 10.5167V9.51666H12V10.5167H3.48333ZM3.48333 6.46666V5.46666H12V6.46666H3.48333ZM3.48333 2.41666V1.41666H12V2.41666H3.48333Z" fill="white"/>\n' +
  '</svg>\n';

icons['list']['bullet'] =
  '<svg width="12" height="10" viewBox="0 0 12 10" fill="white" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path d="M0.85 5.83333C0.616667 5.83333 0.416667 5.75278 0.25 5.59167C0.0833333 5.43056 0 5.23333 0 5C0 4.76667 0.0833333 4.56944 0.25 4.40833C0.416667 4.24722 0.616667 4.16667 0.85 4.16667C1.07222 4.16667 1.26389 4.25 1.425 4.41667C1.58611 4.58333 1.66667 4.77778 1.66667 5C1.66667 5.22222 1.58611 5.41667 1.425 5.58333C1.26389 5.75 1.07222 5.83333 0.85 5.83333ZM0.833333 1.66667C0.6 1.66667 0.402778 1.58611 0.241667 1.425C0.0805555 1.26389 0 1.06667 0 0.833333C0 0.6 0.0805555 0.402778 0.241667 0.241667C0.402778 0.0805557 0.6 0 0.833333 0C1.06667 0 1.26389 0.0805557 1.425 0.241667C1.58611 0.402778 1.66667 0.6 1.66667 0.833333C1.66667 1.06667 1.58611 1.26389 1.425 1.425C1.26389 1.58611 1.06667 1.66667 0.833333 1.66667ZM0.85 10C0.616667 10 0.416667 9.91945 0.25 9.75833C0.0833333 9.59722 0 9.40556 0 9.18333C0 8.95 0.0833333 8.75 0.25 8.58333C0.416667 8.41667 0.616667 8.33333 0.85 8.33333C1.07222 8.33333 1.26389 8.41667 1.425 8.58333C1.58611 8.75 1.66667 8.95 1.66667 9.18333C1.66667 9.40556 1.58611 9.59722 1.425 9.75833C1.26389 9.91945 1.07222 10 0.85 10ZM3.33333 9.66667V8.66667H12V9.66667H3.33333ZM3.33333 5.5V4.5H12V5.5H3.33333ZM3.33333 1.33333V0.333333H12V1.33333H3.33333Z" fill="white"/>\n' +
  '</svg>\n';
