// Utils
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from 'antd';
// Assets
import BackToTopIcon from '../images/moveToTop.svg';
import TransparencyIcon from '../images/OpacityIcon.png';
import Start from '../images/play.svg';
import Stop from '../images/pause.svg';
// Components
import ScrollSpeed from './ScrollSpeed';
import FontSize from './FontSize';
import { setPrompterOnAction } from '../redux/actions/prompterOnActions';
import { setPresentModeAction } from '../redux/actions/presentModeAction';
import { setAdvanceScrollAction } from '../redux/actions/advanceScrollActions';
import { setOverlayModeAction} from '../redux/actions/overlayModeAction';

// CSS
const StartPresentingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  width: max-content;
  margin-right: 33%;
  @media (min-width: 768px) {
    margin: 1px;
    max-width: auto;
    margin-left: -10%;
  }

  .intro-text {
    line-height: 50px;
  }

  .intro-text.closed {
    display: none;
  }

  .button-title {
    font-weight: 800;
    letter-spacing: 0.5px;
    padding: 0 12px;
    font-size: 24px;
  }

  .present-controls {
    // position: absolute; ///////// pang gitna
    height: 0;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;

      &.startBtn {
        width: 52px;
        height: 52px;
        margin-left: 40px;
      }

      &.stopBtn {
        width: 52px;
        height: 52px;
        margin-left: 40px;
      }

      &.backToTopBtn {
        width: 42px;
        height: 24px;
      }
    }

    .control-item {
      padding: 1px;
      // margin-right: 10px;
      margin-bottom: 5px;
      min-width: 45px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      opacity: 1;
      z-index: 9999;
      &:hover {
        transform: scale(1.1);
      }
    }

    .playBtn {
      margin: 0 22px;
    }

    .advance-back {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);

      &:hover {
        transform: scale(-1.2, 1.2);
      }
    }

    .hidden {
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: -1;
      pointer-events: none;
    }
  }

  @media (max-width: 768px) {
    .present-controls {
      width: calc(110vh - 110px);
    }
  }

  @media (min-width: 768px) {
    .present-controls {
      position: relative;
      transform: translate(-20%, 0);
    }
  }

  @media only screen and (min-width:401px) and (max-width:500px) {
    .present-controls {
      position: absolute;
      left:0%;
    
      img {
        margin:10px;
        &.startBtn {
          margin-left: 0px;
        }
        &.stopBtn {
          margin-left: 0px;
        }
        &.fontIcon {
          margin-left: 8px;
        }
        &.backToTopBtn {
          margin-left: 8px;
        }
        &.speedIcon {
          margin-left: 20px;
        }
      }
    }
  }

  @media only screen and (min-width:320px) and (orientation: portrait) {
    .present-controls {
      position: absolute;
      left:0%;
    
      img {
        margin:10px;
        &.startBtn {
          margin-left: 0px;
        }
        &.stopBtn {
          margin-left: 0px;
        }
        &.fontIcon {
          margin-left: 8px;
        }
        &.backToTopBtn {
          margin-left: 8px;
        }
        &.speedIcon {
          margin-left: 10px;
        }
      }
    }
  }

  @media (min-width:260px) and (max-width:280px) {
    .present-controls {
      position: absolute;
      left:0%;
    
      img {
        margin:0px;
        &.startBtn {
          left:0%;
          margin-left: 0px;
        }
        &.stopBtn {
          margin-left: 0px;
        }
        &.fontIcon {
          margin-left: 5px;
        }
        &.backToTopBtn {
          margin-left: 5px;
        }
        &.speedIcon {
          margin-left: 8px;
        }
      }
    }
  }

`;

export default function StartPresenting() {
  const [showScrollSlider, setShowScrollSlider] = useState(false);
  const [showScrollSliderFontSize, setShowScrollSliderFontSize] =
    useState(false);
  const dispatch = useDispatch();
  const [modalOpen] = useState(true);
  
  const overlayOn = useSelector((state) => state.overlayOn);
  const prompterText = useSelector((state) => state.prompterText);
  const prompterOn = useSelector((state) => state.prompterOn);
  const inTeams = useSelector((state) => state.inTeams);
  const shakeTextBox = () => {
    console.log('{StartPresenting} triggered shakeTextBox');

    const element = document.getElementsByClassName('placeHolder')[0];
    element.classList.add('shake');
    setTimeout(() => {
      element.classList.remove('shake');
    }, 500);
  };  
    useEffect(() => {
    if (modalOpen) {
      const editor = document.getElementsByClassName('ql-editor')[0];

      document.addEventListener('keydown', (event) => {
        try {
          if (event.code === 'Space') {
            const path = event.composedPath();
            if (!path.includes(editor)) {
              let startBtn = document.getElementsByClassName('startBtn');
              if (startBtn.length) {
                dispatch(setPrompterOnAction(true));
              } else {
                dispatch(setPrompterOnAction(false));
              }
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      });

      return () => {
        document.removeEventListener('keydown',null);
      };
    }
  }, [dispatch, modalOpen]);

  const startPresentMode = () => {
    console.log('{StartPresenting} triggered startPresentMode');
    if (prompterText.trim() === '' || prompterText === '<p><br></p>') {
      // 11 characters are still in prompter text if we haven't typed anything (default string : <p><br></p>)
      shakeTextBox();
    } else {
      dispatch(setPresentModeAction(false));
      dispatch(setPrompterOnAction(!prompterOn));
    }
  };

  const startOverlay = () => {
    console.log('Overlay start', overlayOn)
      if(!overlayOn) {
      startCapture();
      dispatch(setOverlayModeAction(true)); 
      }
      else if (overlayOn) {
      dispatch(setOverlayModeAction(false));
      stopCapture();
      } 
  }

  const advanceScroll = (direction) => {
    console.log('{StartPresenting} triggered advanceScroll ', direction);
    if (prompterText.trim() === '' || prompterText === '<p><br></p>') {
      shakeTextBox();
    } else {
      dispatch(setPrompterOnAction(false));
      dispatch(setAdvanceScrollAction(direction));
    }
  };

  const openSlider = (action) => {
    // logInfo('{StartPresenting} triggered openSlider ', action);
    if (action) {
      setShowScrollSlider(action);
    } else {
      setShowScrollSlider(action);
    }
  };
  const openSliderFontSize = (action) => {
    console.log('{StartPresenting} triggered openSlider ', action);
    if (action) {
      setShowScrollSliderFontSize(action);
    } else {
      setShowScrollSliderFontSize(action);
    }
  };

  //Overlay Function
  const videoElem = document.getElementById("video");
  const displayMediaOptions = {
    video: {
      displaySurface: "window",
    },
    audio: false,
  }
  async function startCapture() {
      try {
          videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
          videoElem.style.display = "block"
          videoElem.srcObject.getVideoTracks()[0].addEventListener('ended', () => {
            dispatch(setOverlayModeAction(false));
            videoElem.style.display = "none"
          })
      } catch (err) {
        console.error(err);
      }
    }
  function stopCapture(evt) {
      let tracks = videoElem.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoElem.srcObject = null;
    }

  const controlTooltip = () => {
    return (
      <>
        <div className="tooltip">{prompterOn ? 'Pause' : 'Play'}</div>
        <div>{'(Space Bar)'}</div>
      </>
    );
  };

  return (
    <StartPresentingButton>
      <div
        className={
          prompterText.length > 11
            ? 'present-controls open'
            : 'present-controls'
        }
      >
         <div id='overlay'className={
            !prompterOn && !inTeams
              ? 'scroll-speed control-item'
              : 'scroll-speed control-item hidden'
          }onClick={startOverlay}
          style={inTeams ? {display: 'none'} : {}}>
          <Tooltip
          title="Overlay"
            color="#273d57"
            overlayClassName="tooltip"
          >
            <img src={TransparencyIcon} className="Overlay" alt={''} />
          </Tooltip>
        </div>
        <div
          className={
            !prompterOn
              ? 'scroll-speed control-item'
              : 'scroll-speed control-item hidden'
          }
          onMouseOver={() => {
            openSlider(true);
          }}
          onMouseLeave={() => {
            openSlider(false);
          }}
        >
          <ScrollSpeed showScrollSlider={showScrollSlider} />
        </div>
        <div
          className={
            !prompterOn
              ? 'back-to-top control-item'
              : 'back-to-top control-item hidden'
          }
          onClick={() => {
            advanceScroll('top');
          }}
        >
          <Tooltip
            title="Return to Top"
            color="#273d57"
            overlayClassName="tooltip"
          >
            <img src={BackToTopIcon} className="backToTopBtn" alt={''} />
          </Tooltip>
        </div>
        <div
          className={
            !prompterOn
              ? 'scroll-speed control-item'
              : 'scroll-speed control-item hidden'
          }
          onMouseOver={() => {
            openSliderFontSize(true);
          }}
          onMouseLeave={() => {
            openSliderFontSize(false);
          }}
        >
          <FontSize fontSize={showScrollSliderFontSize} />
        </div>
        <div
          className="control-item"
          onClick={startPresentMode}
          style={{ padding: '4px 12px' }}
        >
          {prompterOn ? (
            <Tooltip
              title={controlTooltip()}
              color="#273d57"
              overlayClassName="tooltip"
            >
              <img src={Stop} className="stopBtn" alt="stop" />
            </Tooltip>
          ) : (
            <Tooltip
              title={controlTooltip()}
              color="#273d57"
              overlayClassName="tooltip"
            >
              <img src={Start} className="startBtn" alt="start" />
            </Tooltip>
          )}
        </div>
      </div>
      <ReactTooltip
        id="scroll-speed"
        place="top"
        effect="solid"
        delayShow={800}
      >
        Scroll Speed
      </ReactTooltip>
      <ReactTooltip
        id="scroll-button"
        place="top"
        effect="solid"
        delayShow={800}
      >
        Start Auto-scroll
      </ReactTooltip>
      <ReactTooltip
        id="reduce-scroll-speed"
        place="top"
        effect="solid"
        delayShow={800}
      >
        Reduce Scroll Speed
      </ReactTooltip>
      <ReactTooltip
        id="increase-scroll-speed"
        place="top"
        effect="solid"
        delayShow={800}
      >
        Increase Scroll Speed
      </ReactTooltip>
    </StartPresentingButton>
  );
}
